<template>
  <div id="all-page">
    <defaultTemplate>
      <div class="pd-x-5 pd-y-3">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="is-flex ai-center"
        >
          <el-breadcrumb-item :to="{ path: `/learning/semester/${'123'}` }"
            >All</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: `/subject/detail/${'123'}` }">{{
            subject.name
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ subject.badge.name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="text-center font-1ุ6 mg-b-3 mg-t-5 color-00">
          {{ subject.name }}
        </p>
        <div class="card pd-5">
          <p class="mg-0 font-weight-500">{{ subject.badge.name }}</p>
        </div>
        <p class="font-12 color-main font-weight-500 mg-t-5">
          {{ subject.badge.detail.name }}
        </p>
        <div class="is-flex ai-center js-between mg-t-7">
          <div class="font-12 color-main font-weight-500">
            <span class="circle-green"></span>
            {{ subject.badge.detail.type }}
          </div>
          <span class="font-12 font-weight-500 color-green mg-t-5">
            {{ subject.badge.detail.learningHour }} Learning hour
          </span>
        </div>
        <div class="text-center mg-y-4">
          <img :src="require(`@/assets/${subject.badge.detail.img}`)" class="img-certificate" />
        </div>
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import CardProgress from "@/components/CardProgress.vue";
export default {
  components: {
    defaultTemplate,
    CardProgress,
  },
  data() {
    return {
      activeName: 1,
      subject: {
        id: 1,
        name: "Digital Transformation (DTF)",
        nowHour: 40,
        totalHour: 45,
        percentNow: 90,
        percentTotal: 100,
        term: "1/2020",
        length: 1,
        badge: {
          id: 2,
          name: "DTF.2",
          hour: 3,
          totalHour: 6,
          detail: {
            id: 8,
            name: "Agile for Business Transformation",
            type: "Online Seminar",
            learningHour: 1,
            img: "certificate1.png",
          },
        },
      },
    };
  },
  methods: {},
};
</script>

<style></style>
